import React, { useState } from 'react'
import { createSession }   from 'requests/session'
import { useSession }      from 'contexts/session/hooks'

import ScribeLogo from 'images/ScribeLogo.png'

export default function ApplicationLoggedOutContainer() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const { setCurrentUser } = useSession()

  function handleEmailChange(event: React.FormEvent<HTMLInputElement>) {
    setEmail((event.target as HTMLInputElement).value)
  }

  function handlePasswordChange(event: React.FormEvent<HTMLInputElement>) {
    setPassword((event.target as HTMLInputElement).value)
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    createSession({ email, password })
      .then((response) => {
        setCurrentUser(response.data.admin_user)
      })
      .catch(() => {
        setError('Invalid email or password')
      })
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="w-96">
        <img
          src={ ScribeLogo }
          width="200px"
          alt="Scribe logo"
          className="mx-auto mb-9"
        />
        { error && (
          <div
            className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            { error }
          </div>
        ) }
        <form onSubmit={ handleSubmit }>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Your email
            </label>
            <input
              onChange={ handleEmailChange }
              value={ email }
              type="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Your password
            </label>
            <input
              onChange={ handlePasswordChange }
              value={ password }
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
            />
          </div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  )
}

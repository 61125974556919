import ApplicationLoggedInContainer               from 'components/application/ApplicationLoggedInContainer'
import ApplicationLoggedOutContainer              from 'components/application/ApplicationLoggedOutContainer'
import NewLeadActivationModal                     from 'components/lead-activation/NewLeadActivationModal'
import EditLeadActivationModal                    from 'components/lead-activation/EditLeadActivationModal'
import LeadActivationPage                         from 'components/lead-activation/LeadActivationPage'
import SignaturesList                             from 'components/signatures/SignaturesList'
import AutoTakeover                               from 'components/AutoTakeover'
import { useSession }                             from 'contexts/session/hooks'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import WorkspacesPage                             from 'components/workspaces/WorkspacesPage'
import SubscriptionsPage                          from 'components/subscriptions/SubscriptionsPage'
import EditWorkspaceModal                         from 'components/workspaces/EditWorkspaceModal'

export default function ApplicationRouter() {
  const { currentUser } = useSession()

  return (
    <BrowserRouter>
      <Routes>
        { currentUser && (
          <Route path="/" element={ <ApplicationLoggedInContainer /> }>
            <Route index element={ <SignaturesList /> } />
            <Route path="/signatures" element={ <SignaturesList /> } />
            <Route path="/auto-takeover/:userId" element={ <AutoTakeover /> } />
            <Route path="/signatures/workspaces/:workspaceId" element={ <SignaturesList /> } />
            <Route path="/signatures/departments/:departmentId" element={ <SignaturesList /> } />
            <Route path="/lead-activations" element={ <LeadActivationPage /> }>
              <Route path="/lead-activations/new" element={ <NewLeadActivationModal /> } />
              <Route path="/lead-activations/:leadActivationId/edit" element={ <EditLeadActivationModal /> } />
            </Route>
            <Route path="/subscriptions" element={ <SubscriptionsPage /> } />
            <Route path="/workspaces" element={ <WorkspacesPage /> }>
              <Route path="/workspaces/:workspaceId/edit" element={ <EditWorkspaceModal /> } />
            </Route>
          </Route>
        ) }
        { !currentUser && (
          <>
            <Route path="/" element={ <ApplicationLoggedOutContainer /> } />
            <Route path="*" element={ <Navigate to="/" replace /> } />
          </>
        ) }
      </Routes>
    </BrowserRouter>
  )
}

import { State, useHookstate } from '@hookstate/core'
import { LeadActivation }      from '@scribe/types/src/admin'
import { SelectInput }         from 'components/ui/input/SelectInput'
import { useEffect }           from 'react'
import { useCoupons }          from 'requests/coupons'

type BillingInterval = LeadActivation['billing_interval']

interface CouponInputProps {
  value: State<string | null>
  billingInterval: State<BillingInterval>
}

export default function CouponInput(props: CouponInputProps) {
  const billingInterval = useHookstate(props.billingInterval).get()
  const currentCoupon = props.value.get()
  const { coupons } = useCoupons(billingInterval)

  useEffect(() => {
    // Reset coupon when billing interval changes
    if (currentCoupon && !currentCoupon.includes(billingInterval)) {
      props.value.set(null)
    }
  }, [billingInterval])

  return (
    <SelectInput
      value={ props.value }
      options={ [
        {
          value: null,
          label: 'No coupon',
        },
        ...coupons.map((coupon) => ({
          value: coupon.id,
          label: coupon.id,
        })),
      ] }
    />
  )
}

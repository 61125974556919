import { State }              from '@hookstate/core'
import { Subscription }       from '@scribe/types/src/admin'
import { add }                from 'date-fns'
import { formatToYYYY_MM_DD } from 'lib/time'
import Api                    from 'services/api'

export function moveBackToFreePlan(subscription: State<Subscription>) {
  if (subscription.plan.value !== 'free') {
    throw new Error('Subscription is not in free trial')
  }
  subscription.merge({ ends_at: '' })

  apiUpdateSubscription({ id: subscription.id.value, ends_at: '' })
}

export function startFreeTrial(subscription: State<Subscription>) {
  if (subscription.plan.value !== 'free') {
    throw new Error('Subscription is not in free trial')
  }
  const inAMonth = add(new Date(), { months: 1 })
  const endsAt = formatToYYYY_MM_DD(inAMonth)
  subscription.merge({ ends_at: endsAt })

  apiUpdateSubscription({ id: subscription.id.value, ends_at: endsAt })
}

export function updateSubscription(subscription: State<Subscription>) {
  apiUpdateSubscription(subscription.value)
}

function apiUpdateSubscription(
  subscription: Partial<Subscription> & { id: number }
) {
  const { id, ...params } = subscription
  return Api.put<Subscription>(`/subscriptions/${id}`, {
    subscription: params,
  })
}

import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

export default function Pagination(props: ReactPaginateProps) {
  return (
    <div className="shadow-box-level-2 no-padding app-pagination">
      <ReactPaginate
        previousLabel={ <ChevronLeftIcon className="w-5 h-5" /> }
        nextLabel={ <ChevronRightIcon className="w-5 h-5" /> }
        containerClassName="inline-flex items-center -space-x-px"
        pageClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        pageLinkClassName="py-2 px-3"
        activeClassName="py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
        disabledClassName="disabled"
        previousClassName=""
        nextClassName=""
        previousLinkClassName="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        nextLinkClassName="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        breakClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        breakLinkClassName=""
        pageRangeDisplayed={ 3 }
        marginPagesDisplayed={ 2 }
        { ...props }
      />
    </div>
  )
}

import React from 'react'

function SignatureIframe(props: { content: string }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function writeHTML(frame: any) {
    if (!frame) {
      return
    }
    const doc = frame.contentDocument
    doc.open()
    doc.write(props.content)
    doc.close()
    frame.style.width = '100%'
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`
  }

  return (
    <iframe title="Signature" src="about:blank" scrolling="no" frameBorder="0" ref={ writeHTML } />
  )
}
export default SignatureIframe

import { State, useHookstate }                from '@hookstate/core'
import { Subscription }                       from '@scribe/types/src/admin'
import { getTrialStatus }                     from 'components/subscriptions/utils/subscriptionUtils'
import ButtonDanger                           from 'components/ui/cta/ButtonDanger'
import ButtonSuccess                          from 'components/ui/cta/ButtonSuccess'
import DatePickerInput                        from 'components/ui/input/DatePickerInput'
import { NumberInput }                        from 'components/ui/input/NumberInput'
import { Gap24 }                              from 'components/ui/layout/Gap'
import Row                                    from 'components/ui/layout/Row'
import { DangerPill }                         from 'components/ui/Pills'
import { Label }                              from 'components/ui/Texts'
import { moveBackToFreePlan, startFreeTrial } from 'requests/subscriptions'

interface SubscriptionFormProps {
  subscriptionState: State<Subscription>;
}

export default function SubscriptionForm({ subscriptionState }: SubscriptionFormProps) {
  const subscription = useHookstate(subscriptionState)
  function handleMoveBackToFreePlan() {
    moveBackToFreePlan(subscription)
  }

  function handleStartFreeTrial() {
    startFreeTrial(subscription)
  }

  const trialStatus = getTrialStatus(subscription.get())

  return (
    <>
      <Label>Seat count</Label>
      <NumberInput placeholder={ '∞' } value={ subscription.max_seats_count } />
      <Gap24 />

      <Row>
        <Label>Trial end date</Label>
        { trialStatus === 'expired' && <DangerPill>Expired</DangerPill> }
      </Row>
      <Row className="justify-between">
        { trialStatus !== 'unlimited' && (
          <DatePickerInput value={ subscription.ends_at } />
        ) }
        { trialStatus === 'active' || trialStatus === 'expired' ? (
          <ButtonDanger onClick={ handleMoveBackToFreePlan }>
            Clear date
          </ButtonDanger>
        ) : (
          <ButtonSuccess onClick={ handleStartFreeTrial }>Add date</ButtonSuccess>
        ) }
      </Row>
      <Gap24 />
    </>
  )
}

import LeadActivationForm                                                from 'components/lead-activation/LeadActivationForm'
import ButtonDanger                                                      from 'components/ui/cta/ButtonDanger'
import ButtonPrimary                                                     from 'components/ui/cta/ButtonPrimary'
import Col                                                               from 'components/ui/layout/Col'
import Row                                                               from 'components/ui/layout/Row'
import ApplicationModal                                                  from 'components/ui/layout/ApplicationModal'
import { parseTextToInt }                                                from 'lib/parseUtils'
import { useNavigate, useParams }                                        from 'react-router-dom'
import { deleteLeadActivation, updateLeadActivation, useLeadActivation } from 'requests/lead-activations'

export default function EditLeadActivationModal() {
  const navigate = useNavigate()
  const { leadActivationId } = useParams()
  const leadActivationState = useLeadActivation(parseTextToInt(leadActivationId || ''))
  
  if (!leadActivationState) {
    return null
  }

  // TODO: handle cancel -> revert to previous state
  function handleCloseModal() {
    navigate('/lead-activations')
  }

  function handleDeleteLead() {
    deleteLeadActivation(leadActivationState)
    handleCloseModal()
  }

  async function handleUpdateLead() {
    // TODO: set loading state
    updateLeadActivation(leadActivationState)
    handleCloseModal()
  }

  return (
    <ApplicationModal
      isOpen={ true }
      contentLabel="Edit lead activation"
      onRequestClose={ handleCloseModal }
    >
      <Col>
        <LeadActivationForm leadActivationState={ leadActivationState } />
        <Row className="justify-between">
          <ButtonDanger
            style={ { alignSelf: 'flex-end', width: 'auto' } }
            onClick={ handleDeleteLead }
          >
            Delete
          </ButtonDanger>
          <ButtonPrimary
            style={ { alignSelf: 'flex-end', width: 'auto' } }
            onClick={ handleUpdateLead }
          >
            Save
          </ButtonPrimary>
        </Row>
      </Col>
    </ApplicationModal>
  )
}

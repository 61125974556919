import {
  CouponList,
  CouponListResponse,
  LeadActivation
} from '@scribe/types/src/admin'
import { useEffect, useState } from 'react'
import Api                     from 'services/api'

type BillingInterval = LeadActivation['billing_interval'];

export function useCoupons(billing_interval: BillingInterval) {
  const [coupons, setCoupons] = useState<CouponList>([])

  useEffect(() => {
    getCoupons(billing_interval).then(({ data }) => {
      setCoupons(data.coupons)
    })
  }, [billing_interval])

  return {
    coupons,
  }
}

function getCoupons(billing_interval: BillingInterval) {
  return Api.get<CouponListResponse>('/coupons', {
    params: { billing_interval },
  })
}

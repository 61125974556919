import { createState, none, State, useHookstate } from '@hookstate/core'
import Api                                        from 'services/api'
import { useEffect, useState }                    from 'react'
import {
  LeadActivation,
  LeadActivationList,
  LeadActivationListResponse,
  LeadActivationParams,
  LeadActivationResponse
} from '@scribe/types/src/admin'

export type LeadActivationDraft = LeadActivationParams['lead_activation']

interface LeadActivationPaginatedList {
  list: LeadActivationList
  total_pages: number
  total_count: number
}

const leadActivationListState = createState<LeadActivationPaginatedList>({
  list:        [],
  total_pages: 0,
  total_count: 0,
})

export function useLeadActivation(leadActivationId: number) {
  return leadActivationListState.list.find(
    (leadActivation) => leadActivation.id.value === leadActivationId
  )
}

export type LeadActivationsListFilters = 'pending' | 'activated'
interface LeadActivationsListParams {
  page: number
  filters: LeadActivationsListFilters
}

export function useLeadActivations({
  page,
  filters,
}: LeadActivationsListParams) {
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    setIsFetching(true)
    apiGetLeadActivationList({ page, filters })
      .then((leadActivationsResponse) => {
        leadActivationListState.merge({
          list:        leadActivationsResponse.data.lead_activations,
          total_pages: leadActivationsResponse.data.pagination.total_pages,
          total_count: leadActivationsResponse.data.pagination.total_count,
        })
      })
      .finally(() => {
        setIsFetching(false)
      })
  }, [page, filters])
  const leadActivationsListState = useHookstate(leadActivationListState)
  const status = isFetching ? 'loading' : 'success'

  return {
    leadActivationsList: leadActivationsListState.list,
    totalPages:          leadActivationsListState.total_pages,
    totalCount:          leadActivationsListState.total_count,
    status,
  }
}

export async function addLeadActivation(lead: LeadActivationDraft) {
  const { data } = await apiCreateLeadActivation(lead)
  leadActivationListState.list.set((prevArray) => {
    prevArray.splice(0, 0, data.lead_activation)
    return prevArray
  })
  return data.lead_activation
}

export function updateLeadActivation(lead: State<LeadActivation> | undefined) {
  if (!lead) {
    return
  }
  apiUpdateLeadActivation(lead.get())
}

export function deleteLeadActivation(lead: State<LeadActivation> | undefined) {
  if (!lead) {
    return
  }
  apiDeleteLeadActivation(lead.id.value)
  lead.set(none)
}

async function apiGetLeadActivationList({
  page,
  filters,
}: LeadActivationsListParams) {
  const response = await Api.get<LeadActivationListResponse>(
    '/lead_activations',
    {
      params: { page, activated: filters === 'activated' },
    }
  )
  const filteredLeadActivations = response.data.lead_activations.filter(
    (lead) => (filters === 'pending' ? !lead.activated_at : lead.activated_at)
  )

  return {
    ...response,
    data: {
      ...response.data,
      lead_activations: filteredLeadActivations,
    },
  }
}

async function apiCreateLeadActivation(leadActivation: LeadActivationDraft) {
  return Api.post<LeadActivationResponse>('/lead_activations', {
    lead_activation: leadActivation,
  })
}

async function apiUpdateLeadActivation(
  leadActivation: Partial<LeadActivation>
): Promise<LeadActivation> {
  return Api.put(`/lead_activations/${leadActivation.id}`, {
    lead_activation: leadActivation,
  })
}
async function apiDeleteLeadActivation(
  leadActivationId: number
): Promise<void> {
  return Api.delete(`/lead_activations/${leadActivationId}`)
}

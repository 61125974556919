import React              from 'react'
import { createRoot }     from 'react-dom/client'
import SessionProvider    from 'contexts/session/SessionProvider'
import ApplicationRouter  from 'components/application/ApplicationRouter'
import Bugsnag            from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import './index.css'
import 'flowbite'
import '@hookstate/devtools'

if (!process.env.REACT_APP_BUGSNAG_API_KEY) {
  throw new Error('BUGSNAG_API_KEY environment variable is not set')
}

Bugsnag.start({
  apiKey:               process.env.REACT_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ['production', 'staging'],
  releaseStage:         process.env.REACT_APP_ENV,
  plugins:              [new BugsnagPluginReact()],
})

function ErrorBoundary(props: { children: React.ReactNode }) {
  const plugin = Bugsnag.getPlugin('react')

  if (plugin) {
    const ErrorBoundaryWrapper = plugin.createErrorBoundary(React)
    return <ErrorBoundaryWrapper children={ props.children } />
  } else {
    return <>{ props.children }</>
  }
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <SessionProvider>
          <ApplicationRouter />
        </SessionProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}

import styled from 'styled-components'

export const Gap8 = styled.div`
  height: 8px;
`
export const Gap12 = styled.div`
  height: 12px;
`
export const Gap16 = styled.div`
  height: 16px;
`
export const Gap24 = styled.div`
  height: 24px;
`

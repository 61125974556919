import LeadActivationEmptyList from 'components/lead-activation/LeadActivationEmptyList'
import LeadActivationItem      from 'components/lead-activation/LeadActivationItem'
import { Loader, Pagination }  from 'components/ui'
import Col                     from 'components/ui/layout/Col'
import Row                     from 'components/ui/layout/Row'
import { useState }            from 'react'
import {
  LeadActivationsListFilters,
  useLeadActivations
} from 'requests/lead-activations'

interface LeadActivationListProps {
  filters: LeadActivationsListFilters;
}

export default function LeadActivationList({
  filters,
}: LeadActivationListProps) {
  const [page, setPage] = useState(1)

  const { leadActivationsList, status, totalPages } = useLeadActivations({
    page,
    filters,
  })

  if (status === 'loading') {
    return <Loader />
  }

  if (leadActivationsList.length === 0) {
    return <LeadActivationEmptyList filters={ filters } />
  }

  function handlePageChange({ selected }: { selected: number }) {
    setPage(selected + 1)
  }

  return (
    <Col className="gap-2">
      { leadActivationsList.map((leadActivation, index) => (
        <LeadActivationItem key={ index } leadActivation={ leadActivation } />
      )) }
      <Row className="mt-5 mb-5 justify-center">
        { totalPages.get() > 0 && (
          <Pagination
            pageCount={ totalPages.get() }
            onPageChange={ handlePageChange }
            disableInitialCallback
            forcePage={ page - 1 }
          />
        ) }
      </Row>
    </Col>
  )
}

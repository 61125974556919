import { AxiosResponseHeaders } from 'axios'
import Cookies                  from 'js-cookie'

const accessTokenCookieName = 'admin_access_token'
const accessTokenExpireAtCookieName = 'admin_access_token_expire_at'
const refreshTokenCookieName = 'admin_refresh_token'

function cookieAttributes() {
  return {
    domain:  process.env.REACT_APP_COOKIE_DOMAIN,
    secure:  process.env.REACT_APP_PROTOCOL === 'https',
    expires: 155,
  } // 155 days, 5 months
}

export function setAccessToken(authorizationToken: string) {
  return Cookies.set(
    accessTokenCookieName,
    authorizationToken,
    cookieAttributes()
  )
}

export function setAccessTokenExpireAt(value: string) {
  Cookies.set(accessTokenExpireAtCookieName, value, cookieAttributes())
}

export function setRefreshToken(value: string) {
  Cookies.set(refreshTokenCookieName, value, cookieAttributes())
}

export function getRefreshToken() {
  return Cookies.get(refreshTokenCookieName)
}

export function getAccessToken() {
  return Cookies.get(accessTokenCookieName)
}

export function deleteAuthorization() {
  Cookies.remove(accessTokenCookieName, cookieAttributes())
  Cookies.remove(accessTokenExpireAtCookieName, cookieAttributes())
  Cookies.remove(refreshTokenCookieName, cookieAttributes())
}

export function setAuthorizationFromHeaders(headers: AxiosResponseHeaders) {
  if (
    headers['access-token'] &&
    headers['expire-at'] &&
    headers['refresh-token']
  ) {
    setAccessToken(headers['access-token'])
    setAccessTokenExpireAt(headers['expire-at'])
    setRefreshToken(headers['refresh-token'])
  }
}

export function getAuthorizationHeader() {
  if (getAccessToken()) {
    return `Bearer ${getAccessToken()}`
  }
  return undefined
}

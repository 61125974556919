import { Loader }          from 'components/ui'
import Col                 from 'components/ui/layout/Col'
import WorkspaceItem       from 'components/workspaces/WorkspaceItem'
import WorkspacesEmptyList from 'components/workspaces/WorkspacesEmptyList'
import { useWorkspaces }   from 'requests/workspaces'

interface WorkspaceListProps {
  ownerEmail?: string;
  withFreePlanAndEndDate?: boolean;
}

export default function WorkspacesList(props: WorkspaceListProps) {
  const workspacesListState = useWorkspaces({
    ownerEmail:             props.ownerEmail,
    withFreePlanAndEndDate: props.withFreePlanAndEndDate,
  })

  if (workspacesListState.status.get() === 'loading') {
    return <Loader />
  }

  if (workspacesListState.list.length === 0) {
    return <WorkspacesEmptyList />
  }

  return (
    <Col className="gap-2">
      { workspacesListState.list.map((workspace, index) => (
        <WorkspaceItem key={ index } workspace={ workspace } />
      )) }
    </Col>
  )
}

import axios                                                                                         from 'axios'
import { getAuthorizationHeader, setAuthorizationFromHeaders, getRefreshToken, deleteAuthorization } from 'services/authorization'
import createAuthRefreshInterceptor                                                                  from 'axios-auth-refresh'

const defaultHeaders = {
  Accept:          'application/json',
  'X-Client-Type': 'AdminWepApplication',
  'X-Api-Version': 4,
}

const api = axios.create({
  headers: defaultHeaders,
  baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_HOST}/admin`,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function refreshAuthLogic(failedRequest: any) {
  if (!getAuthorizationHeader() || !getRefreshToken()) {
    return Promise.reject()
  }
  return axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_HOST}/admin/sessions/tokens`, {}, { headers: { ...defaultHeaders, Authorization: getAuthorizationHeader() || '', 'Refresh-Token': getRefreshToken() || '' } }).then((tokenRefreshResponse) => {
    setAuthorizationFromHeaders(tokenRefreshResponse.headers)
    failedRequest.response.config.headers.Authorization = getAuthorizationHeader()
    return Promise.resolve()
  }).catch((error) => {
    deleteAuthorization()
    return Promise.reject(error)
  })
}

createAuthRefreshInterceptor(api, refreshAuthLogic)

api.interceptors.request.use(
  (config) => {
    const authorizationHeader = getAuthorizationHeader()
    if (authorizationHeader) {
      (config.headers || {}).Authorization = authorizationHeader
    }
    return config
  },
  (error) => Promise.reject(error)
)

api.interceptors.response.use(
  (response) => {
    setAuthorizationFromHeaders(response.headers)
    return response
  }, (error) => {
    return Promise.reject(error)
  }
)

export default api

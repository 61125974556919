import min                    from 'lodash/min'
import round                  from 'lodash/round'
import concat                 from 'lodash/concat'
import { PayingSubscription } from '@scribe/types/src/admin'
import { formatToYYYY_MM_DD } from 'lib/time'
import classNames             from 'classnames'

export default function SubscriptionsListItem(props: { subscription: PayingSubscription }) {
  const { subscription } = props

  const completionPercentage = round(min([(subscription.installed_signature_count / subscription.minimum_seats_count) * 100, 100]) || 0, 2)

  const integrations = concat(subscription.data_source_integrations, subscription.signature_installation_destination_integrations)

  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={ subscription.id }>
      <td className="py-4 px-6">{ subscription.created_at && formatToYYYY_MM_DD(new Date(subscription.ends_at)) }</td>
      <td className="py-4 px-6">{ subscription.workspace_name }</td>
      <td className="py-4 px-6">{ subscription.workspace_size }</td>
      <td className="py-4 px-6">
        { subscription.installed_signature_count } / { subscription.minimum_seats_count }
      </td>
      <td>{ completionPercentage }%</td>
      <td className={ classNames('py-4 px-6', { 'text-red-600': subscription.cancel_at_period_end }) }>
        { subscription.amount_cents && (
          <>
            { Intl.NumberFormat('en-US', { style: 'currency', currency: subscription.currency_code }).format(subscription.amount_cents / 100) } { subscription.billing_interval }
          </>
        ) }
        { subscription.cancel_at_period_end && <><br/>Canceled</> } 
      </td>
      <td className="py-4 px-6">
        { integrations.map((integration) => (
          <>
            { integration }
            <br />
          </>
        )) }
      </td>
      <td className="py-4 px-6">{ subscription.owner_email }</td>
    </tr>
  )
}

import CheckboxInput                             from 'components/ui/input/CheckboxInput'
import TextInput                                 from 'components/ui/input/TextInput'
import Col                                       from 'components/ui/layout/Col'
import Row                                       from 'components/ui/layout/Row'
import { Label }                                 from 'components/ui/Texts'
import WorkspacesList                            from 'components/workspaces/WorkspacesList'
import { useCreateHookState, useDebouncedValue } from 'lib/toolbox'
import { Outlet }                                from 'react-router-dom'

export default function WorkspacesPage() {
  const ownerEmailState = useCreateHookState('')
  const withFreePlanAndEndDate = useCreateHookState(false)
  const ownerEmailSearch = useDebouncedValue(ownerEmailState.get(), 300)
  return (
    <Col>
      <Row className="mb-8 justify-between">
        <TextInput
          value={ ownerEmailState }
          placeholder="Search by owner email"
        />
        <Row className="align-middle gap-2">
          <Label noMargin>Free trial only</Label>
          <CheckboxInput value={ withFreePlanAndEndDate } />
        </Row>
      </Row>
      <WorkspacesList
        ownerEmail={ ownerEmailSearch }
        withFreePlanAndEndDate={ withFreePlanAndEndDate.get() }
      />
      { /* Used to render the edit modal when clicking a workspace */ }
      <Outlet />
    </Col>
  )
}

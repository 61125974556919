import { useEffect }          from 'react'
import { useParams }          from 'react-router-dom'
import { createUserTakeover } from 'requests/takeover'
import { getSession }         from 'requests/session'
import { useSession }         from 'contexts/session/hooks'

function AutoTakeover() {
  const { userId } = useParams()
  const { setCurrentUser } = useSession()

  async function handleTakeover() {
    if (userId) {
      await createUserTakeover(parseInt(userId, 10))
    }
    const sessionResponse = await getSession()
    setCurrentUser(sessionResponse.data.admin_user)
    window.location.replace(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_APP_HOST}/reload`
    )
  }

  useEffect(() => {
    handleTakeover()
  }, [])

  return (
    <div>
      <h1>User automatic takeover ...</h1>
    </div>
  )
}

export default AutoTakeover

import React      from 'react'
import { Admin }  from '@scribe/types/src/admin'
import classNames from 'classnames'

export default function SubscriptionsListHeader(props: {
  column?: Admin.PayingSubscriptionsIndex.RequestQuery['order_by']
  title: string
  onChange?: (column: Admin.PayingSubscriptionsIndex.RequestQuery['order_by']) => void
  orderBy?: string
  orderDirection?: string,
  colSpan?: number
}) {
  const onChange = props.onChange
  return (
    <th
      scope="col"
      colSpan={ props.colSpan }
      className={ classNames('py-3 px-6', { 'hover:bg-gray-200 cursor-pointer': props.onChange, 'bg-gray-200': props.column && props.column === props.orderBy }) }
      { ...(onChange && props.column ? { onClick: () => onChange(props.column) } : {}) }
    >
      { props.title }
      { props.column === props.orderBy && (
        <>
          { props.orderDirection && props.orderDirection === 'asc' && <span className="ml-2">▲</span> }
          { props.orderDirection && props.orderDirection === 'desc' && <span className="ml-2">▼</span> }
        </>
      ) }
    </th>
  )
}

import { LeadActivationsListFilters } from 'requests/lead-activations'

export default function LeadActivationEmptyList(props: {
  filters: LeadActivationsListFilters;
}) {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="text-center">
        <h1 className="text-3xl font-bold opacity-30">
          { props.filters === 'pending'
            ? 'No lead to activate ☀️'
            : 'No activated lead yet. Keep pushing mate 🏎' }
        </h1>
      </div>
    </div>
  )
}

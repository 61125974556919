import { State, useHookstate } from '@hookstate/core'
import DatePicker              from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { formatToYYYY_MM_DD }  from 'lib/time'

interface DatePickerInputProps {
  value: State<string | null> | State<string>;
}

export default function DatePickerInput(props: DatePickerInputProps) {
  const currentDate = useHookstate(props.value)
  const date = currentDate.ornull
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ? new Date(currentDate.get() as any)
    : new Date()
  return (
    <DatePicker
      selected={ date }
      onChange={ (date: Date) => props.value.set(formatToYYYY_MM_DD(date)) }
      dateFormat="yyyy-MM-dd"
      className="bg-gray-100 border-0 border-gray-200 rounded-lg p-3 w-48"
    />
  )
}

import { XIcon }             from '@heroicons/react/solid'
import { applicationColors } from 'components/ui/styles/application-colors'
import Modal                 from 'react-modal'
import styled                from 'styled-components'

interface ApplicationModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  contentLabel?: string;
  hideCloseButton?: boolean;
  children: React.ReactNode;
}

export default function ApplicationModal(props: ApplicationModalProps) {
  return (
    <Modal
      isOpen={ true }
      style={ customStyles }
      contentLabel={ props.contentLabel }
      onRequestClose={ props.onRequestClose }
    >
      { !props.hideCloseButton && (
        <CloseButtonIcon onClick={ props.onRequestClose }>
          <XIcon className="w-5 h-5" />
        </CloseButtonIcon>
      ) }
      { props.children }
    </Modal>
  )
}

Modal.setAppElement('#root')

const customStyles = {
  content: {
    minWidth:        380,
    top:             '50%',
    left:            '50%',
    right:           'auto',
    bottom:          'auto',
    marginRight:     '-50%',
    transform:       'translate(-50%, -50%)',
    backgroundColor: 'white',
    border:          'none',
    padding:         32,
    borderRadius:    12,
    zIndex:          100,
    overflow:        'visible', // to allow datePicker to render above modal
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex:          99,
  },
}

const CloseButtonIcon = styled.button`
  background: none;
  color: ${applicationColors.grey700}
  border: none;
  outline: none;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 12px;
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
  :hover {
    opacity: 1;
  }
  :active {
    transform: scale(0.9);
  }
`

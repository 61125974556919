import Api from 'services/api'

import { createState, useHookstate } from '@hookstate/core'

import {
  Workspace,
  WorkspaceList,
  WorkspaceListResponse
} from '@scribe/types/src/admin'

import { useEffect } from 'react'

interface WorkspaceListState {
  list: WorkspaceList;
  status: 'loading' | 'loaded' | 'error';
}

const workspacesState = createState<WorkspaceListState>({
  list:   [],
  status: 'loading',
})

export function useWorkspace(workspaceId: number) {
  return workspacesState.list.find(
    (workspace) => workspace.id.value === workspaceId
  )
}

interface UseWorkspacesProps {
  ownerEmail?: string;
  withFreePlanAndEndDate?: boolean;
}
export function useWorkspaces({
  ownerEmail = '',
  withFreePlanAndEndDate = false,
}: UseWorkspacesProps = {}) {
  const workspacesListState = useHookstate(workspacesState)
  useEffect(() => {
    workspacesState.merge({ status: 'loading' })
    getWorkspaces(ownerEmail, withFreePlanAndEndDate)
      .then((response) => {
        workspacesState.merge({
          status: 'loaded',
          list:   response.data.workspaces,
        })
      })
      .catch((error) => {
        console.error(error)
        workspacesState.merge({ status: 'error' })
      })
  }, [ownerEmail, withFreePlanAndEndDate])
  return workspacesListState
}

export function deleteWorkspace(workspace: Workspace) {
  apiDeleteWorkspace(workspace)
  workspacesState.list.set((list) =>
    list.filter((item) => item.id !== workspace.id)
  )
}

function getWorkspaces(ownerEmail: string, withFreePlanAndEndDate: boolean) {
  return Api.get<WorkspaceListResponse>('/workspaces', {
    params: {
      owner_email:                 ownerEmail,
      with_free_plan_and_end_date: withFreePlanAndEndDate,
    },
  })
}

function apiDeleteWorkspace(workspace: Workspace) {
  return Api.delete(`/workspaces/${workspace.id}`, {
    params: { owner_email: workspace.owner_email },
  })
}

import React         from 'react'
import { AdminUser } from '@scribe/types/src/admin'

// TODO: Move this to HookState
interface ISessionContext {
  currentUser: AdminUser | undefined;
  setCurrentUser: (currentUser: AdminUser | undefined) => void;
  deleteCurrentUser: () => void;
}

const defaultState = {
  currentUser:       undefined,
  setCurrentUser:    () => {},
  deleteCurrentUser: () => {},
}

const SessionContext = React.createContext<ISessionContext>(defaultState)

export default SessionContext

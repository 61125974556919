import { applicationColors } from 'components/ui/styles/application-colors'
import styled                from 'styled-components'

export const H1 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 60px;
  letter-spacing: -0.4px;
  color: ${applicationColors.grey700};
  margin: 0px;
`
export const H2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.4px;
  margin: 0px;
`
export const H3 = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: ${applicationColors.grey700};
  margin: 0px;
`
export const H4 = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: ${applicationColors.grey700};
  margin: 0px;
`
export const Label = styled.div<{ noMargin?: boolean }>`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: ${applicationColors.grey700};
  margin-bottom: ${(props) => (props.noMargin ? 0 : 12)}px;
`

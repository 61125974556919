import { State, useHookstate } from '@hookstate/core'
import { useState }            from 'react'
import styled                  from 'styled-components'
import { applicationColors }   from '../styles/application-colors'

interface SwitchInputProps<T> {
  value: State<T>;
  placeholder?: string;
  options: SwitchOption<T>[];
  style?: React.CSSProperties;
}

export interface SwitchOption<T> {
  readonly value: T;
  label: string;
  onSelect?: (value: T) => void;
}

export function SwitchInput<T>(props: SwitchInputProps<T>) {
  const selectedValue = useHookstate(props.value)
  const [selectedIndex, setSelectedIndex] = useState(
    props.options.findIndex(({ value }) => value === selectedValue.get()) || 0
  )

  function handleClick(index: number) {
    setSelectedIndex(index)
    props.value.set(props.options[index].value)
    props.options[index]?.onSelect?.(props.options[index].value)
  }

  return (
    <Container nOptions={ props.options.length } style={ props.style }>
      { props.options.map((option, index) => (
        <LabelContainer
          key={ index }
          onClick={ () => {
            handleClick(index)
          } }
        >
          <Label isSelected={ index === selectedIndex }>{ option.label }</Label>
        </LabelContainer>
      )) }

      <Slider selectedIndex={ selectedIndex } nOptions={ props.options.length } />
    </Container>
  )
}

const Container = styled.div<{ nOptions: number }>(
  ({ nOptions }) => `
  display: flex;
  height: 48px;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  background-color: ${applicationColors.grey100};
  width: ${nOptions * 150}px;
`
)

const LabelContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1;
`

const Label = styled.div<{ isSelected: boolean }>(
  ({ isSelected }) => `
  transition: all 0.2s ease-in-out;
  z-index: 2;
  font-size: 16px;
  margin: 0 10px 0 10px;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  transform: ${isSelected ? 'scale(1)' : 'scale(0.9)'};
  color: ${
  isSelected ? `${applicationColors.grey700}` : `${applicationColors.grey500}`
};
`
)

const Slider = styled.div<{ nOptions: number; selectedIndex: number }>(
  ({ nOptions, selectedIndex }) => `
  display: flex;
  position: relative;
  flex-direction: row;
  z-index: 1;
  background-color: ${applicationColors.white};
  height: 40px;
  width: 50%;
  border-radius: 24px;
  position: absolute;
  left: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 0 5px;
  width: calc(${100 / nOptions}% - 10px);
  transition: all 0.2s ease-in-out;
  transform: translate(calc(${100 * selectedIndex}% + ${selectedIndex * 10}px));
`
)

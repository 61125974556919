import { State, useHookstate } from '@hookstate/core'
import { Validation }          from '@hookstate/validation'
import LeadActivationForm      from 'components/lead-activation/LeadActivationForm'
import ButtonPrimary           from 'components/ui/cta/ButtonPrimary'
import Col                     from 'components/ui/layout/Col'
import ApplicationModal        from 'components/ui/layout/ApplicationModal'
import { useCreateHookState }  from 'lib/toolbox'
import { useNavigate }         from 'react-router-dom'
import useHotKey               from 'react-use-hotkeys'
import {
  addLeadActivation,
  LeadActivationDraft
} from 'requests/lead-activations'

export default function NewLeadActivationModal() {
  const navigate = useNavigate()
  const leadActivationState = useCreateHookState<LeadActivationDraft>({
    company_name:      '',
    seat_count:        0,
    billing_interval:  'yearly',
    collection_method: 'charge_automatically',
    coupon:            null,
    have_sso:          false,
  })

  function handleCloseModal() {
    navigate('/lead-activations')
  }

  async function handleCreateLead() {
    addLeadActivation(leadActivationState.get())
    handleCloseModal()
  }
  return (
    <ApplicationModal
      isOpen={ true }
      contentLabel="Create lead activation"
      onRequestClose={ handleCloseModal }
    >
      <Col>
        <LeadActivationForm
          leadActivationState={ leadActivationState }
          autoFocus
        />
        <CreateLeadButton
          onClick={ handleCreateLead }
          leadActivationState={ leadActivationState }
        />
      </Col>
    </ApplicationModal>
  )
}

function CreateLeadButton(props: {
  onClick: () => void;
  leadActivationState: State<LeadActivationDraft>;
}) {
  const state = useHookstate(props.leadActivationState)

  // 📚 https://hookstate.js.org/docs/extensions-validation
  state.attach(Validation)
  // configure rules
  Validation(state).validate(
    (lead) => !!lead.company_name && lead.seat_count > 0,
    'You should at least fill in the company name and seat count'
  )
  const isValid = Validation(state).valid()

  useHotKey(
    'Meta+enter',
    () => {
      if (isValid) {
        props.onClick()
      }
    },
    []
  )

  return (
    <ButtonPrimary
      style={ { alignSelf: 'flex-end', width: 'auto' } }
      onClick={ props.onClick }
      status={ isValid ? 'enabled' : 'disabled' }
    >
      Create Lead
    </ButtonPrimary>
  )
}

import { State, useHookstate }             from '@hookstate/core'
import { LeadActivation }                  from '@scribe/types/src/admin'
import Col                                 from 'components/ui/layout/Col'
import Row                                 from 'components/ui/layout/Row'
import { applicationColors }               from 'components/ui/styles/application-colors'
import styled                              from 'styled-components'
import { LinkIcon, DocumentDuplicateIcon } from '@heroicons/react/solid'
import { H4 }                              from 'components/ui/Texts'
import { useNavigate }                     from 'react-router-dom'

interface LeadActivationItemProps {
  leadActivation: State<LeadActivation>;
}

export default function LeadActivationItem(props: LeadActivationItemProps) {
  const navigate = useNavigate()
  const leadActivationState = useHookstate(props.leadActivation).get()

  function handleEditLeadActivation() {
    navigate(`/lead-activations/${leadActivationState.id}/edit`)
  }
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCopyClick(event: any) {
    event.stopPropagation()
    navigator.clipboard.writeText(leadActivationState.url)
  }
  
  return (
    <Container onClick={ handleEditLeadActivation }>
      <Col className="flex-1">
        <Row className="gap-2">
          <H4>{ leadActivationState.company_name }</H4>
          <Item className="text-center">
            { leadActivationState.seat_count } seats
          </Item>
        </Row>
      </Col>
      { leadActivationState.have_sso && (
        <Item style={ { alignSelf: 'center' } }>SSO</Item>
      ) }
      <PreviewLink onClick={ handleCopyClick }>
        <DocumentDuplicateIcon className="w-4 h-4" />
        Copy
      </PreviewLink>
      <PreviewLink
        href={ leadActivationState.url }
        target="_blank"
        onClick={ (event) => event.stopPropagation() }
      >
        <LinkIcon className="w-4 h-4" />
        Preview
      </PreviewLink>
      
    </Container>
  )
}

const Container = styled.button`
  background-color: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  width: 100%;
  align-items: center;
  padding: 12px;
  gap: 12px;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.01);
  }
`

const Item = styled.div`
  font-weight: 600;
  color: ${applicationColors.grey500};
  font-size: 14px;
  align-self: end;
`

const PreviewLink = styled.a`
  padding: 8px 12px;
  align-items: center;
  background-color: ${applicationColors.primary};
  border-radius: 8px;
  color: white;
  gap: 4px;
  display: inline-flex;
`

import Api         from 'services/api'
import { Session } from '@scribe/types/src/admin'

export function getSession(): Promise<{ data: Session }> {
  return Api.get('/sessions')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createSession(attributes: { email: string; password: string; }): Promise<{ data: Session; headers: { [key: string]: any } }> {
  return Api.post('/sessions', { admin_user: attributes })
}

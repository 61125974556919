import classNames from 'classnames'

interface ButtonPrimaryProps {
  onClick: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  // Default status is "enabled"
  status?: 'enabled' | 'disabled'; // | "loading";
  className?: string;
}

export default function ButtonPrimary(props: ButtonPrimaryProps) {
  const disabled = props.status === 'disabled'
  return (
    <button
      type="submit"
      style={ { width: 'auto', height: 40, ...props.style } }
      disabled={ disabled }
      className={ classNames(
        'flex flex-row gap-2 text-white bg-blue-700  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ',
        props.className,
        {
          'hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800':
            !disabled,
          'opacity-50': disabled,
        }
      ) }
      onClick={ props.onClick }
    >
      { props.children }
    </button>
  )
}

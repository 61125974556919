import { InputContainer }    from 'components/ui/input/InputContainer'
import { applicationColors } from 'components/ui/styles/application-colors'
import { Label }             from 'components/ui/Texts'
import styled                from 'styled-components'

export interface BaseInputProps {
  value: string;
  style?: React.CSSProperties;
  className?: string;
  autoFocus?: boolean;
  onChangeText?: (text: string) => void;
  type?: 'text' | 'number';
  placeholder?: string;
}

export default function BaseInput({
  style,
  onChangeText,
  placeholder,
  className,
  ...restProps
}: BaseInputProps) {
  const hidePlaceholder = restProps.value !== ''
  return (
    <InputContainer style={ style } className={ className }>
      <Input
        autoCorrect="off"
        onChange={ (event) => onChangeText?.(event.target.value) }
        { ...restProps }
      />
      { placeholder && (
        <Placeholder
          style={ {
            opacity:   hidePlaceholder ? 0 : 1,
            transform: hidePlaceholder
              ? 'translate(20px)'
              : 'translate(0) scale(1)',
          } }
        >
          { placeholder }
        </Placeholder>
      ) }
    </InputContainer>
  )
}

const Input = styled.input`
  appearance: none;
  height: 24px;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: ${applicationColors.grey700};
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  z-index: 1;
  :focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
`

const Placeholder = styled(Label)`
  z-index: 2;
  position: absolute;
  pointer-events: none;
  top: 14px;
  left: 12px;
  margin: 0;
  color: ${applicationColors.grey300};
  transition: all 100ms;
  transition-timing-function: ease-out;
`

import { State, useHookstate } from '@hookstate/core'
import { applicationColors }   from 'components/ui/styles/application-colors'
import { ChangeEvent }         from 'react'
import styled                  from 'styled-components'

interface SelectInputProps<T> {
  value: State<T | null>;
  options: SelectOption<T>[];
}

interface SelectOption<T> {
  value: T | null;
  label: string;
}

export function SelectInput<T extends string | number>(
  props: SelectInputProps<T>
) {
  const selectedValue = useHookstate(props.value)
  function handleSelect(changeEvent: ChangeEvent<HTMLSelectElement>) {
    const selectedValue = changeEvent.target.value || null
    const index = props.options.findIndex(
      (option) => option.value === selectedValue
    )
    props.value?.set(props.options[index].value)
  }

  return (
    <Container>
      <StyledSelect onChange={ handleSelect } value={ selectedValue.get() || '' }>
        { props.options.map((opt) => (
          <option key={ opt.value } value={ opt.value || '' }>
            { opt.label }
          </option>
        )) }
      </StyledSelect>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-radius: 24px;
  background-color: ${applicationColors.grey100};
`

const StyledSelect = styled.select`
  border: none;
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: ${applicationColors.grey700};
  width: 100%;
  margin: 0 8px;
  :focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`

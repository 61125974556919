import { format, formatDistance } from 'date-fns'

export function formatTimeRelative(YYYY_MM_DD: string): string {
  const now = new Date()
  const time = new Date(YYYY_MM_DD)
  const distance = formatDistance(time, now, { addSuffix: true })

  return `${distance} (${format(time, 'd MMMM yyyy')})`
}

export function formatToYYYY_MM_DD(date: Date): string {
  return format(date, 'yyyy-MM-dd')
}

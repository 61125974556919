export function parseTextToInt(
  text: string | undefined,
  defaultValue = 0
): number {
  if (!text) {
    return 0
  }
  const parsedValue = parseInt(text)
  if (isNaN(parsedValue)) {
    return defaultValue
  }
  return parsedValue
}

import { createState, State, useHookstate } from '@hookstate/core'

import { useEffect, useMemo, useState } from 'react'

export function useCreateHookState<T>(initialValue: T): State<T> {
  const state = useMemo(() => createState(initialValue), [])
  return useHookstate(state)
}

export function generateRandomNumberId() {
  return Math.round(Math.random() * 1_000_000_000)
}

export function getStringFromState(
  state: State<string> | State<string | null>
) {
  return state.get() || ''
}

export function useDebouncedValue<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}

import { State }               from '@hookstate/core'
import { LeadActivation }      from '@scribe/types/src/admin'
import CouponInput             from 'components/lead-activation/CouponInput'
import { NumberInput }         from 'components/ui/input/NumberInput'
import { SwitchInput }         from 'components/ui/input/SwitchInput'
import TextInput               from 'components/ui/input/TextInput'
import { Gap24 }               from 'components/ui/layout/Gap'
import { Label }               from 'components/ui/Texts'
import { LeadActivationDraft } from 'requests/lead-activations'

interface LeadActivationFormProps {
  leadActivationState: State<LeadActivationDraft> | State<LeadActivation>
  autoFocus?: boolean
}

export default function LeadActivationForm({
  leadActivationState,
  autoFocus = false,
}: LeadActivationFormProps) {
  return (
    <>
      <Label>Company name</Label>
      <TextInput
        autoFocus={ autoFocus }
        placeholder={ 'SpaceX' }
        value={ leadActivationState.company_name }
      />
      <Gap24 />

      <Label>Seat count</Label>
      <NumberInput
        placeholder={ 'XXXX' }
        value={ leadActivationState.seat_count }
      />
      <Gap24 />

      <Label>Collection method</Label>
      <SwitchInput<LeadActivationDraft['collection_method']>
        placeholder={ 'Collection method' }
        value={ leadActivationState.collection_method }
        options={ [
          { value: 'charge_automatically', label: 'Credit card' },
          { value: 'send_invoice', label: 'Invoice' },
        ] }
      />
      <Gap24 />

      <Label>Billing interval</Label>
      <SwitchInput<LeadActivationDraft['billing_interval']>
        placeholder={ 'Billing interval' }
        value={ leadActivationState.billing_interval }
        options={ [
          { value: 'monthly', label: 'Monthly' },
          { value: 'yearly', label: 'Yearly' },
        ] }
      />
      <Gap24 />

      <Label>SSO</Label>
      <SwitchInput
        placeholder={ 'SSO' }
        value={ leadActivationState.have_sso }
        options={ [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ] }
      />
      <Gap24 />

      <Label>Coupon</Label>
      <CouponInput
        value={ leadActivationState.coupon }
        billingInterval={ leadActivationState.billing_interval }
      />
      <Gap24 />
    </>
  )
}

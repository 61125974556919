import { TrashIcon }                     from '@heroicons/react/solid'
import { Workspace }                     from '@scribe/types/src/admin'
import classNames                        from 'classnames'
import includes                          from 'lodash/includes'
import SubscriptionForm                  from 'components/subscriptions/SubscriptionForm'
import ButtonDanger                      from 'components/ui/cta/ButtonDanger'
import ButtonPrimary                     from 'components/ui/cta/ButtonPrimary'
import ButtonSuccess                     from 'components/ui/cta/ButtonSuccess'
import TextInput                         from 'components/ui/input/TextInput'
import ApplicationModal                  from 'components/ui/layout/ApplicationModal'
import Col                               from 'components/ui/layout/Col'
import { Gap24 }                         from 'components/ui/layout/Gap'
import Row                               from 'components/ui/layout/Row'
import { H3, H4 }                        from 'components/ui/Texts'
import { parseTextToInt }                from 'lib/parseUtils'
import { useSession }                    from 'contexts/session/hooks'
import { useCreateHookState }            from 'lib/toolbox'
import { useState }                      from 'react'
import { useNavigate, useParams }        from 'react-router-dom'
import { updateSubscription }            from 'requests/subscriptions'
import { deleteWorkspace, useWorkspace } from 'requests/workspaces'
import {
  createWorkspaceTakeover,
  deleteWorkspaceTakeover
} from 'requests/takeover'
import { Initial }    from '@hookstate/initial'
import { Touched }    from '@hookstate/touched'
import { getSession } from 'requests/session'

export default function EditWorkspaceModal() {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const workspaceState = useWorkspace(parseTextToInt(workspaceId || ''))

  const { currentUser, setCurrentUser } = useSession()

  if (!workspaceState) {
    return null
  }

  workspaceState.attach(Initial)
  workspaceState.attach(Touched)

  function handleCloseModal() {
    if (workspaceState && Touched(workspaceState.subscription).touched()) {
      updateSubscription(workspaceState.subscription)
    }
    navigate('/workspaces')
  }

  async function handleTakeover() {
    if (workspaceState) {
      await createWorkspaceTakeover(workspaceState.id.get())
    }
    const sessionResponse = await getSession()
    setCurrentUser(sessionResponse.data.admin_user)
    window.open(
      `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_APP_HOST}/reload`
    )
  }

  async function handleCancelTakeover() {
    await deleteWorkspaceTakeover()
    const sessionResponse = await getSession()
    setCurrentUser(sessionResponse.data.admin_user)
  }

  return (
    <ApplicationModal
      isOpen={ true }
      contentLabel="Edit subscription"
      hideCloseButton={ true }
      onRequestClose={ handleCloseModal }
    >
      <Col>
        <Row className="justify-between">
          <H3>{ workspaceState.name.get() }</H3>
          <DeleteWorkspaceButton workspace={ workspaceState.get() } />
        </Row>
        <Gap24 />
        <SubscriptionForm subscriptionState={ workspaceState.subscription } />
      </Col>
      <Row className="justify-end">
        { currentUser &&
          !includes(
            currentUser.workspace_takeover_ids,
            workspaceState.id.get()
          ) && (
          <ButtonSuccess
            className="mr-4"
            style={ { alignSelf: 'flex-start', width: 'auto' } }
            onClick={ handleTakeover }
          >
              Takeover workspace
          </ButtonSuccess>
        ) }
        { currentUser &&
          includes(
            currentUser.workspace_takeover_ids,
            workspaceState.id.get()
          ) && (
          <ButtonDanger
            className="mr-4"
            style={ { alignSelf: 'flex-start', width: 'auto' } }
            onClick={ handleCancelTakeover }
          >
              Cancel takeover
          </ButtonDanger>
        ) }
        <ButtonPrimary
          style={ { alignSelf: 'flex-end', width: 'auto' } }
          onClick={ handleCloseModal }
        >
          Done
        </ButtonPrimary>
      </Row>
    </ApplicationModal>
  )
}

function DeleteWorkspaceButton(props: { workspace: Workspace }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const navigate = useNavigate()

  const workspaceOwnerEmailConfirmation = useCreateHookState('')

  const buttonDeleteStatus =
    workspaceOwnerEmailConfirmation.get() === props.workspace.owner_email
      ? 'enabled'
      : 'disabled'

  function handleDeleteWorkspace() {
    deleteWorkspace(props.workspace)
    setShowDeleteConfirmation(false)
    navigate('/workspaces')
  }

  return (
    <>
      <button
        className="text-coolGray-400 hover:text-red-400 transition-all"
        onClick={ () => {
          setShowDeleteConfirmation(true)
        } }
      >
        <TrashIcon className="w-4 h-4" />
      </button>

      <Col
        style={ {
          padding:         32,
          borderRadius:    12,
          backgroundColor: 'white',
        } }
        className={ classNames(
          'absolute top-0 left-0 right-0 bottom-0 z-50 transition-all duration-150',
          {
            'opacity-0 pointer-events-none':   !showDeleteConfirmation,
            'opacity-100 pointer-events-auto': showDeleteConfirmation,
            'translate-y-4':                   !showDeleteConfirmation,
          }
        ) }
      >
        <H4 style={ { marginBottom: 12 } }>
          Are you sure you want to delete { props.workspace.name }?
        </H4>
        <Col className="flex flex-1 justify-center">
          Please type the workspace owner email to confirm. <br />
          <span className="bg-red-100 text-red-500 rounded px-1 select-none">
            { props.workspace.owner_email }
          </span>
          <TextInput
            className="mt-4"
            value={ workspaceOwnerEmailConfirmation }
            placeholder={ 'Confirm here' }
          />
        </Col>
        <Row className="justify-between mt-6">
          <ButtonPrimary onClick={ () => setShowDeleteConfirmation(false) }>
            Cancel
          </ButtonPrimary>
          <ButtonDanger
            onClick={ handleDeleteWorkspace }
            status={ buttonDeleteStatus }
          >
            Delete { props.workspace.name }
          </ButtonDanger>
        </Row>
      </Col>
    </>
  )
}

import { applicationColors } from 'components/ui/styles/application-colors'
import styled                from 'styled-components'

export const DangerPill = styled.div`
  margin-left: 8px;
  background-color: ${applicationColors.danger500};
  color: white;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 18px;
  border-radius: 6px;
  padding: 0px 4px;
`

import { State, useHookstate } from '@hookstate/core'
import BaseInput               from 'components/ui/input/BaseInput'
import { parseTextToInt }      from 'lib/parseUtils'
import { CSSProperties }       from 'styled-components'

interface NumberInputProps {
  value: State<number | null> | State<number>
  placeholder?: string
  autoFocus?: boolean
  style?: CSSProperties
  inputStyle?: CSSProperties
}

export function NumberInput(props: NumberInputProps) {
  const currentValue = useHookstate((props.value || 0).ornull)
  const textValue = currentValue.ornull?.get()?.toString() || ''
  return (
    <BaseInput
      autoFocus={ props.autoFocus }
      style={ props.inputStyle }
      onChangeText={ (newValue) => {
        props.value.set(parseTextToInt(newValue, 0))
      } }
      type={ 'number' }
      value={ textValue === '0' ? '' : textValue }
      placeholder={ props.placeholder }
    />
  )
}

import classNames from 'classnames'

interface ButtonSuccessProps {
  onClick: () => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  status?: 'enabled' | 'disabled';
}

export default function ButtonSuccess(props: ButtonSuccessProps) {
  const disabled = props.status === 'disabled'
  return (
    <button
      type="submit"
      disabled={ disabled }
      style={ {
        width:      'auto',
        height:     '40px',
        lineHeight: '36px',
        ...props.style,
      } }
      className={ classNames(
        'flex flex-row gap-2 text-green-500 border-2 border-green-500 bg-white font-medium rounded-lg text-sm sm:w-auto px-5',
        props.className,
        'transition-opacity',
        {
          'opacity-50': disabled,
          'hover:bg-green-100 focus:ring-4 focus:outline-none focus:ring-green-300':
            !disabled,
        }
      ) }
      onClick={ props.onClick }
    >
      { props.children }
    </button>
  )
}

import { State, useHookstate } from '@hookstate/core'
import { Workspace }           from '@scribe/types/src/admin'
import Col                     from 'components/ui/layout/Col'
import Row                     from 'components/ui/layout/Row'
import { applicationColors }   from 'components/ui/styles/application-colors'
import DotSeparator            from 'components/ui/layout/DotSeparator'
import { formatTimeRelative }  from 'lib/time'
import { useNavigate }         from 'react-router-dom'
import styled                  from 'styled-components'
import { getTrialStatus }      from 'components/subscriptions/utils/subscriptionUtils'
import { DangerPill }          from 'components/ui/Pills'

interface WorkspaceItemProps {
  workspace: State<Workspace>;
}

export default function WorkspaceItem(props: WorkspaceItemProps) {
  const workspaceState = useHookstate(props.workspace).get()
  const navigate = useNavigate()

  function showEditSubscriptionModal() {
    navigate(`/workspaces/${workspaceState.id}/edit`)
  }

  const trialStatus = getTrialStatus(workspaceState.subscription)

  return (
    <Container onClick={ showEditSubscriptionModal }>
      <Col className="flex-1">
        <Row className="gap-2">
          <Item>{ workspaceState.name }</Item>
          <SubItem>{ workspaceState.owner_email }</SubItem>
        </Row>
        <Row>
          { workspaceState.subscription?.max_seats_count ? (
            <SubItem>
              { workspaceState.subscription.max_seats_count } seats
              <DotSeparator />
            </SubItem>
          ) : (
            <SubItem>
              ∞ seats
              <DotSeparator />
            </SubItem>
          ) }
          { trialStatus === 'active' && workspaceState.subscription?.ends_at && (
            <DateItem>
              Free trial ends:{ ' ' }
              { formatTimeRelative(workspaceState.subscription.ends_at) }
            </DateItem>
          ) }
          { trialStatus === 'expired' && workspaceState.subscription?.ends_at && (
            <DateItem>
              Free trial ends:{ ' ' }
              { formatTimeRelative(workspaceState.subscription.ends_at) }
              <DangerPill>Trial Expired</DangerPill>
            </DateItem>
          ) }
          { trialStatus === 'unlimited' && <DateItem>Free plan</DateItem> }
        </Row>
      </Col>
    </Container>
  )
}

const Container = styled.button`
  background-color: white;
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  width: 100%;
  align-items: center;
  padding: 12px;
  gap: 12px;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.01);
  }
`

const Item = styled.div`
  display: flex;
  font-weight: 600;
  color: ${applicationColors.grey900};
  font-size: 14px;
  align-self: end;
`

const SubItem = styled.div`
  display: flex;
  font-weight: 600;
  color: ${applicationColors.grey500};
  font-size: 14px;
  align-self: end;
`

const DateItem = styled.div`
  display: flex;
  font-weight: 500;
  color: ${applicationColors.grey500};
  font-size: 14px;
`

import { PlusIcon }                   from '@heroicons/react/solid'
import LeadActivationList             from 'components/lead-activation/LeadActivationList'
import ButtonPrimary                  from 'components/ui/cta/ButtonPrimary'
import { SwitchInput }                from 'components/ui/input/SwitchInput'
import Col                            from 'components/ui/layout/Col'
import Row                            from 'components/ui/layout/Row'
import { useCreateHookState }         from 'lib/toolbox'
import { Outlet, useNavigate }        from 'react-router-dom'
import { LeadActivationsListFilters } from 'requests/lead-activations'

export default function LeadActivationPage() {
  const navigate = useNavigate()
  const filters = useCreateHookState<LeadActivationsListFilters>('pending')
  function handleClick() {
    navigate('/lead-activations/new')
  }
  return (
    <Col>
      <Row className="justify-between mb-8">
        <SwitchInput
          value={ filters }
          options={ [
            { value: 'pending', label: 'Pending' } as const,
            { value: 'activated', label: 'Activated' } as const,
          ] }
        />
        <ButtonPrimary onClick={ handleClick }>
          <PlusIcon className="w-5 h-5" />
          Create lead activation
        </ButtonPrimary>
      </Row>
      <LeadActivationList filters={ filters.get() } />
      { /* Used to render the create lead activation modal */ }
      <Outlet />
    </Col>
  )
}

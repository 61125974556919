import React, { useEffect, useState }                              from 'react'
import { Pagination }                                              from 'components/ui'
import { PayingSubscription, Pagination as PaginationType, Admin } from '@scribe/types/src/admin'
import { getPayingSubscriptions }                                  from 'requests/paying_subscriptions'
import SubscriptionsListItem                                       from 'components/subscriptions/SubscriptionsListItem'
import SubscriptionsListHeader                                     from 'components/subscriptions/SubscriptionsListHeader'
import { useDebounce }                                             from 'use-debounce'

export default function SubscriptionsList() {
  const [subscriptions, setSubscriptions] = useState<Array<PayingSubscription>>([])
  const [pagination, setPagination] = useState<PaginationType>()
  const [currentPage, setCurrentPage] = useState(0)
  const [billingInterval, setBillingInterval] = useState<Admin.PayingSubscriptionsIndex.RequestQuery['billing_interval']>('yearly')
  const [companyName, setCompanyName] = useState('')
  const [orderBy, setOrderBy] = useState<Admin.PayingSubscriptionsIndex.RequestQuery['order_by']>('ends_at')
  const [orderDirection, setOrderDirection] = useState<Admin.PayingSubscriptionsIndex.RequestQuery['order_direction']>('asc')

  const [debouncedCompanyName] = useDebounce(companyName, 300)

  function handleBillingIntervalChange (event: React.FormEvent<HTMLSelectElement>) {
    setBillingInterval(event.currentTarget.value as Admin.PayingSubscriptionsIndex.RequestQuery['billing_interval'])
  }

  function handlePageChange(page: { selected: number }) {
    if (page.selected !== currentPage) {
      setCurrentPage(page.selected)
    }
  }

  function changeOrder(columnName: Admin.PayingSubscriptionsIndex.RequestQuery['order_by']) {
    if (columnName === orderBy) {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(columnName)
      setOrderDirection('asc')
    }
  }

  async function populateSubscriptions() {
    const response = await getPayingSubscriptions({ page: currentPage, per_page: 15, order_by: orderBy, order_direction: orderDirection, workspace_name: companyName, billing_interval: billingInterval })
    setSubscriptions(response.data.paying_subscriptions)
    setPagination(response.data.pagination)
  }

  function handleCompanyNameChange(event: React.FormEvent<HTMLInputElement>) {
    setCompanyName(event.currentTarget.value)
  }

  useEffect(() => {
    populateSubscriptions()
  }, [currentPage, debouncedCompanyName, billingInterval])

  useEffect(() => {
    if (currentPage === 0) {
      populateSubscriptions()
    } else {
      setCurrentPage(0)
    }
  }, [orderBy, orderDirection])

  return (
    <>
      <div className="grid grid-cols-4 gap-4 mb-5">
        <div>
          <label htmlFor="company-size" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
            Company name
          </label>
          <input
            type="text"
            id="company-size"
            onChange={ handleCompanyNameChange }
            value={ companyName }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div>
          <label
            htmlFor="subscription-kind"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
              Billing interval
          </label>
          <select
            id="subscription-kind"
            onChange={ handleBillingIntervalChange }
            value={ billingInterval }
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="all">All</option>
            <option value="yearly">Yearly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
      </div>
      <div className="overflow-x-auto relative">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <SubscriptionsListHeader title={ 'Renewal' } column={ 'ends_at' } orderBy={ orderBy } orderDirection={ orderDirection } onChange={ changeOrder } />
              <SubscriptionsListHeader title={ 'Company' } column={ 'workspace_name' } orderBy={ orderBy } orderDirection={ orderDirection } onChange={ changeOrder } />
              <SubscriptionsListHeader title={ 'Size' } column={ 'company_size' } orderBy={ orderBy } orderDirection={ orderDirection } onChange={ changeOrder } />
              <SubscriptionsListHeader title={ 'Signatures installed' } colSpan={ 2 } />
              <SubscriptionsListHeader title={ 'Amount' } column={ 'subscription_amount' } orderBy={ orderBy } orderDirection={ orderDirection } onChange={ changeOrder } />
              <SubscriptionsListHeader title={ 'Integrations' } />
              <SubscriptionsListHeader title={ 'Owner email' } />
            </tr>
          </thead>
          <tbody>
            { subscriptions.map((subscription) => (
              <SubscriptionsListItem key={ subscription.id } subscription={ subscription } />
            )) }
          </tbody>
        </table>
        <div className="mt-5 mb-5">{ pagination && <Pagination pageCount={ pagination.total_pages } onPageChange={ handlePageChange } disableInitialCallback forcePage={ currentPage } /> }</div>
      </div>
    </>
  )
}

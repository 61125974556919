import { CSSProperties }     from 'react'
import { applicationColors } from 'components/ui/styles/application-colors'
import styled                from 'styled-components'
import Col                   from 'components/ui/layout/Col'

interface InputContainerProps {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

export function InputContainer(props: InputContainerProps) {
  return <Container style={ props.style } className={ props.className }>{ props.children }</Container>
}

const Container = styled(Col)`
  overflow: hidden;
  height: 48px;
  border-radius: 8px;
  position: relative;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  background-color: ${applicationColors.grey50};
  :focus-within {
    background-color: ${applicationColors.grey100};
  }
`

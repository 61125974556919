import { State, useHookstate }       from '@hookstate/core'
import BaseInput, { BaseInputProps } from 'components/ui/input/BaseInput'

interface TextInputProps extends Omit<BaseInputProps, 'type' | 'value'> {
  value: State<string> | State<string | null>;
}

export default function TextInput(props: TextInputProps) {
  const textValue = useHookstate(props.value)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: any = textValue.ornull ? textValue.get() : ''

  return (
    <BaseInput
      type="text"
      { ...props }
      value={ value || '' }
      onChangeText={ (newValue) => {
        props.value.set(newValue)
      } }
    />
  )
}

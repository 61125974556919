import React              from 'react'
import { Outlet }         from 'react-router-dom'
import ApplicationSidebar from 'components/application/ApplicationSidebar'

function ApplicationLoggedInContainer() {
  return (
    <div className="application-container">
      <div className="flex h-full min-h-screen">
        <ApplicationSidebar />
        <div className="w-full px-4 py-2 bg-gray-200 lg:w-full min-h-screen">
          <div className="container mx-auto mt-12">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicationLoggedInContainer

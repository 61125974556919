import { Subscription }       from '@scribe/types/src/admin'
import { formatToYYYY_MM_DD } from 'lib/time'

export type TrialStatus = 'unlimited' | 'active' | 'expired';

export function getTrialStatus(subscription?: Subscription): TrialStatus {
  if (!subscription?.ends_at) {
    return 'unlimited'
  }
  const today = formatToYYYY_MM_DD(new Date())
  if (subscription.ends_at <= today) {
    return 'expired'
  }
  return 'active'
}

import { State } from '@hookstate/core'

interface CheckboxInputProps {
  value: State<boolean>;
}

export default function CheckboxInput(props: CheckboxInputProps) {
  return (
    <input
      type={ 'checkbox' }
      checked={ props.value.get() }
      onChange={ (arg) => {
        props.value.set(arg.target.checked)
      } }
    />
  )
}

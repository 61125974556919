import Api from 'services/api'

export function createWorkspaceTakeover(workspaceId: number) {
  return Api.post(`/workspace_takeover?workspace_id=${workspaceId}`)
}

export function createUserTakeover(userId: number) {
  return Api.post(`/workspace_takeover?user_id=${userId}`)
}

export function deleteWorkspaceTakeover() {
  return Api.delete('/workspace_takeover')
}

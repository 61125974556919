import React, { useState, useEffect } from 'react'
import PropTypes                      from 'prop-types'
import SessionContext                 from 'contexts/session/SessionContext'
import { getSession }                 from 'requests/session'
import { Loader }                     from 'components/ui'
import { AdminUser }                  from '@scribe/types/src/admin'

function SessionProvider(props: { children: React.ReactNode }) {
  const [loading, setLoading] = useState<boolean>(true)
  const [currentUser, setCurrentUser] = useState<AdminUser | undefined>()

  function deleteCurrentUser() {
    setCurrentUser(undefined)
  }

  useEffect(() => {
    getSession()
      .then((response) => {
        setCurrentUser(response.data.admin_user)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <SessionContext.Provider
      value={ {
        currentUser,
        setCurrentUser,
        deleteCurrentUser,
      } }
    >
      { props.children }
    </SessionContext.Provider>
  )
}

SessionProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default SessionProvider
